import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import DashboardService from "../services/dashboard.service";
import CommonService from "../services/common.service";

const initialState = {
  isLoading: false,
  dashboardData: [],
  lastRoundDetails: {},
  budget_left: 0,
  budget_used: 0,
  remaining_trades: 0,
  userData: {},
  game_info_block_data: {},
  team_view: 0,
};

export const getDashboardData = createAsyncThunk(
  "stat/getDashboardData",
  async (thunkAPI) => {
    try {
      const data = await DashboardService.getDashboardData();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserProfileData = createAsyncThunk(
  "dasbhoard/getUserProfileData",
  async (thunkAPI) => {
    try {
      const data = await CommonService.getLoginUserData();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [getDashboardData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload.details;
      state.top_player_overall = action.payload.top_player_overall;
      state.top_player_by_gwk_point = action.payload.top_player_by_gwk_point;
      state.upcomming_fixtures = action.payload.upcomming_fixtures;
      state.gamePower = action.payload.gamePower;
      state.userPayment = action.payload.userPayment;
      state.lastRoundDetails = action.payload.lastRoundDetails;
      state.budget_left = action.payload.budget_left;
      state.budget_used = action.payload.budget_used;
      state.remaining_trades = action.payload.remaining_trades;
      state.rank_points = action.payload.rank_points;
      state.avgPoints = action.payload.avgPoints;
      state.game_info_block_data = action.payload.game_info_block_data;
      state.team_view = action.payload.details?.team_view;
    },
    [getDashboardData.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getUserProfileData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUserProfileData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userData = action.payload.data;
    },
    [getUserProfileData.rejected]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = DashboardSlice;
export default reducer;
