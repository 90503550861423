import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CommonService from "../../services/common.service";
import { Link } from "react-router-dom";
const PointModal = (props) => {
  // Create a state variable to hold the selected grade, initializing it to an empty string or the default value you prefer
  const [selectedGrade, setSelectedGrade] = useState(0);
  const [pointsListing, setPointsListing] = useState([]);

  // Function to handle changes to the selected grade
  const handleGradeChange = (value) => {
    var gradeValue = 0;
    setSelectedGrade(value);
    gradeValue = 0;
    if (value) {
      gradeValue = value;
    }
    CommonService.getGradePointSystem(gradeValue).then((res) => {
      setPointsListing(res.data);
    });
  };
  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
      >
        <Modal.Header>Points System</Modal.Header>
        <Modal.Body>
          <div
            className="card-body"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <select
              id="gradeSelect"
              value={selectedGrade ?? 0}
              onChange={(e) => handleGradeChange(e.target.value)}
              className="form-control"
            >
              <option value="">Select</option> {/* Add a default option */}
              {props.gradeList.map((item, key) => (
                <option key={key} value={item?.id}>
                  {item?.grade}
                </option>
              ))}
            </select>
            <div className="pointsystem-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>CATEGORY</th>
                    <th>PG</th>
                    <th>SG</th>
                    <th>FWD</th>
                    <th>CEN</th>
                  </tr>
                </thead>
                <tbody>
                  {pointsListing &&
                    pointsListing.map((item, key) => (
                      <tr key={key}>
                        <td>{item?.attribute_name}</td>
                        <td>{item?.pg}</td>
                        <td>{item?.sg}</td>
                        <td>{item?.fwd}</td>
                        <td>{item?.cen}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="/images/logo.png" alt="img" />
          </div>
          <div className="">
            <Link
              className="border-btn"
              onClick={(e) => {
                e.preventDefault();
                props.setVisible(false);
              }}
            >
              Close
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PointModal;
